import React from 'react';
import './App.css';
import { isMobile } from 'react-device-detect';
import imagen1 from './Mariachi/Imagen de WhatsApp 2024-05-08 a las 02.46.15_65af9e89.jpg'
import imagen2 from './Mariachi/Imagen de WhatsApp 2024-05-08 a las 02.45.58_679a6f79.jpg'
import imagen3 from './Mariachi/Imagen de WhatsApp 2024-05-08 a las 02.45.57_48fbd997.jpg'
import imagen4 from './Mariachi/Imagen de WhatsApp 2024-05-08 a las 02.45.56_b6e0a234.jpg'
import imagen5 from './Mariachi/Imagen de WhatsApp 2024-05-08 a las 02.45.56_b3027a7e.jpg'
import imagen6 from './Mariachi/Imagen de WhatsApp 2024-05-08 a las 02.45.55_33834dcf.jpg'

function Footer() {
  const handleChatClick = () => {
    redirectToWhatsApp();
  };

  const redirectToWhatsApp = () => {
    console.log(String(isMobile));
    const phoneNumber = '+573165002396'; // Reemplaza esto con el número de teléfono deseado
    const message = encodeURIComponent('Hola, quiero obtener mas información sobre sus servicios de mariachi. \n'); // Reemplaza con tu mensaje predeterminado
  
    if (isMobile) {
      window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
    } else {
      const chatConfirmed = window.confirm('¿Quieres chatear con nosotros por WhatsApp?');
      if (chatConfirmed) {
        window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`, '_blank');
      }
    }
  };
  

  return (
    <footer>
      <p>
      <button id='whatsapp' onClick={handleChatClick}>Chatear por WhatsApp</button>
      </p>
    </footer>

  );
}

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Mariachi Perla de Pereira    3165002396<p>Somos los mejores en la ciudad.</p></h1>
      </header>
      <body className="App-body">
        <div>
          {/* Aquí puedes agregar tus videos de YouTube */}
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Zdz5BkDDUnA" title="Video de YouTube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div>
          {/* Aquí puedes agregar tus imágenes */}
          <div className='trajeta-info'>
            <div className='info'>
              <h2>Amplio repertorio musical</h2>
              <p>Nos enorgullece ofrecer un amplio repertorio de canciones para el Día de la Madre</p>
              <p>Aunque No Sea Mayo</p>
              <p>Es mi madre de Jhonny Rivera</p>
              <p>Paso a la Reina</p>
              <p>Madrecita Ideal</p>
              <p>Clavelitos</p>
              <p>Mamá de Arturo Vargas</p>
              <p>Cielito Lindo</p>
              <p>Amor Eterno</p>
              <p>Madrecita Querida</p>
              <p>Las Mañanitas</p>
              <p>entre otras canciones que llega al corazón.</p>
            </div>
            <img src={imagen1} alt="Mariachi en pereira" />
          </div>
          <div className='trajeta-info'>
            <img src={imagen2} alt="serenata de Mariachi" />
            <div className='info'>
              <h2>Mariachi juvenil</h2>
              <p>Nuestro Mariachi está lleno de energía y pasión por la música. Con una mezcla de talento joven y experiencia, ofrecemos un espectáculo vibrante y emocionante que captura la esencia del mariachi tradicional. Nuestros músicos jóvenes son dedicados y comprometidos con la excelencia musical, y su entusiasmo es contagioso, garantizando una experiencia inolvidable para su evento.</p>
              <p> ¡Permítenos hacer del Día de la Madre un día aún más especial con la magia de nuestra música! </p>
            </div>
          </div>
          <div className='trajeta-info'>
            <div className='info'>
              <h2>Excelente presentación</h2>
              <p>Nos enorgullece nuestra presentación impecable. Nuestros trajes de mariachi son auténticos y están bien cuidados, reflejando nuestro compromiso con la tradición y la profesionalidad. Nuestra música es ejecutada con precisión y sentimiento, y nos esforzamos por crear una atmósfera de alegría y celebración en cada actuación. Con nuestro Mariachi, no solo obtienes una gran música, sino también una presentación excepcional que deleitará a tus invitados.</p>
            </div>
            <img src={imagen3} alt="canciones de dia de la madre" />
          </div>
          <div className='trajeta-info2'>
          <img src={imagen4} alt="regalo serenata" />
          <img src={imagen5} alt="mariachi juvenil" />
          <img src={imagen6} alt="Mariachi" />
          </div>
        </div>
      </body>
      <Footer />
    </div>
  );
};

export default App;
